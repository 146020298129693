import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import React from "react";
import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import styles from "./home.module.scss";

const testimonialScrl = {
  loop: true,
  margin: 30,
  autoplay: true,
  dots: true,
  autoplayTimeout: 8500,
  smartSpeed: 450,
  nav: true,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1261: {
      items: 3,
    },
  },
};

const Home = () => {
  return (
    <>
      <Header />

      <section className={styles.banner_section} id="banner-sec">
        <div className="container">
          <div className="row">
            <div id="about-sec" className="col-md-6">
              <div className={styles.banner_content}>
                <h1>Credit Genius | Free AI-Powered Credit Monitoring App!</h1>
                <p>
                  Credit Genius acts as your personal credit mentor to help you
                  achieve an 800 credit score quickly by using AI and
                  gamification!
                </p>

                <div className={styles.banner_action}>
                  <div className={styles.action_link}>
                    <a className="dfltBtn" href="/">
                      <span>Sign Up</span>
                    </a>
                    <a href="/" className={styles.login_btn}>
                      Log in
                    </a>
                  </div>
                  <div className={styles.qr_code}>
                    <img src={"./images/frame.png"} alt="qr-code" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.banner_img}>
                <img
                  src={"./images/banner-img.png"}
                  alt="AI-powered & gamification"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.about_content}>
        <div className="container">
          <div id="personalCredit" className="row align-items-center">
            <div className="col-md-6 text-center">
              <img
                src={"./images/credit-report-img.png"}
                alt="credit score improvement report"
              />
            </div>
            <div className="col-md-6">
              <h2>Personal Credit</h2>
              <h3>Personal Credit</h3>
              <p>
                Credit Genius is an financial literacy platform revolutionizing
                the way people look at their credit and finances. Our premier
                feature, Credit Genius acts as your personalized credit advisor!
              </p>
              <a className="dfltBtn" href="#">
                <span>Learn More</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.genius_section} ${styles.about_content}`}>
        <div className="container">
          <div id="creditGenius" className="row align-items-center">
            <div className="col-md-6 order-md-2">
              <img
                src={"./images/credit-genius-img.png"}
                alt="credit-genius-credit monitoring"
              />
            </div>
            <div className="col-md-6 order-md-1">
              <h2>credit genius</h2>
              <h3>Get answers to all of your questions with credit genius!</h3>
              <p>
                Credit Genius is an educational platform revolutionizing people
                look at their credit and finance. Learn the step by step
                strategies, mysteries and secrets that has been hidden from us.
              </p>
              <a className="dfltBtn" href="#">
                <span>Learn More</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.about_content}>
        <div className="container">
          <div id="creditGames" className="row align-items-center">
            <div className="col-md-6 text-center">
              <img src={"./images/credit-games-img.png"} alt="credit-games" />
            </div>
            <div className="col-md-6">
              <h2>Credit Games</h2>
              <h3>
                Learn Credit Easy with <span></span>Credit Games!
              </h3>
              <p>
                Credit Genius is an financial literacy platform revolutionizing
                the way people look at their credit and finances. Our premier
                feature.
              </p>
              <a className="dfltBtn" href="#">
                <span>Learn More</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="creditSimulator" className={styles.simulattor_section}>
        <div className={styles.simulattor_img_section}>
          <img src={"./images/simulattor-img.jpg"} alt="credit-simulator" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h2>Credit simulator</h2>
              <h3>Credit Simulator</h3>
              <p>
                Credit Genius is an financial literacy platform revolutionizing
                the way people look at their credit.
              </p>
              <a className="dfltBtn" href="#">
                <span>Learn More</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.offer_content}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <img
                className={styles.mobImg}
                src={"./images/offers-img.png"}
                alt="credit-card-offer"
              />
            </div>
            <div className="col-md-5">
              <h2>Credit Card Offers</h2>
              <h3>Credit Card Offers</h3>
              <p>
                Credit Genius is an financial literacy platform revolutionizing
                the way people look at their credit and finances. Our premier
                feature.
              </p>
              <a className="dfltBtn" href="#">
                <span>Learn More</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.blog_section}>
        <div className="container">
          <h3>Our Blogs</h3>
          <OwlCarousel className="myTestimonialCrsl" {...testimonialScrl}>
            <div className={styles.item}>
              <a
                href="https://medium.com/credit-genius/how-to-reach-an-800-credit-score-a-practical-guide-80cba0122b01"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img01.jpg"}
                      alt="credit genius blog-1"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>How to Reach an 800 Credit Score: A Practical Guide</h4>
                    <p>
                      Achieving an 800 credit score is a significant milestone
                      that can open up many financial opportunities, from
                      securing the best loan rates to qualifying for premium
                      credit cards.{" "}
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://www.forbes.com/sites/forbesfinancecouncil/2024/08/01/unlocking-the-enigma-10-hidden-credit-secrets-revealed/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img02.jpg"}
                      alt="credit genius blog-2"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      Unlocking The Enigma: 10 Hidden Credit Secrets Revealed
                    </h4>
                    <p>
                      Although credit is an important part of both personal and
                      commercial finances, the typical consumer is often unaware
                      of many of its complexities.{" "}
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/gamification-in-the-credit-genius-app-making-financial-learning-fun-c76ca55afece"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img03.jpg"}
                      alt="credit genius blog-3"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      Gamification in Credit Genius App: Making Financial
                      Learning Fun
                    </h4>
                    <p>
                      Credit Genius app has revolutionized credit education by
                      incorporating gamification elements into its platform.{" "}
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://www.forbes.com/sites/forbesfinancecouncil/2024/07/09/the-future-of-finance-4-tips-to-stand-out-above-the-rest/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img04.jpg"}
                      alt="credit genius blog-4"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      The Future Of Finance: 4 Tips To Stand Out Above The Rest
                    </h4>
                    <p>
                      Staying ahead is no longer an option in finance—it's a
                      must. The industry is experiencing changes driven by
                      advancements and shifting customer demands. Companies that
                      don't keep up with these shifts face the risk of falling
                      behind.{" "}
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/5-secret-credit-card-hacks-ccba58282120"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img05.jpg"}
                      alt="credit genius blog-5"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>5 Secret Credit Card Hacks</h4>
                    <p>
                      Credit cards offer a range of benefits beyond just
                      convenient payments, and knowing how to maximize these
                      advantages can significantly enhance your financial
                      experience.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://www.forbes.com/councils/forbesfinancecouncil/2023/03/07/credit-101-the-lost-subject/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img06.jpg"}
                      alt="credit genius blog-6"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>Credit 101: The Lost Subject</h4>
                    <p>
                      Have you ever given any thought to the question of why
                      credit is not taught in schools? They teach us to get a
                      degree, and they teach us to get a career
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/ai-driven-credit-scoring-revolutionizing-credit-and-empowering-society-3ac88f7ab656"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img07.jpg"}
                      alt="credit genius blog-7"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      AI-Driven Credit Scoring: Revolutionizing Credit and
                      Empowering Society
                    </h4>
                    <p>
                      As we move further into the digital age, artificial
                      intelligence (AI) is transforming industries across the
                      globe, and the credit sector is no exception.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://www.forbes.com/councils/forbesfinancecouncil/2022/07/21/multi-millionaire-mindset-mastery/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img08.jpg"}
                      alt="credit genius blog-8"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>Multi-Millionaire Mindset Mastery</h4>
                    <p>
                      The connecting thread of multi-millionaires boils down to
                      one similarity—mindset. These economic actors weave a
                      pattern of unspoken rules that are simply thoughts and
                      philosophies put into action
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/the-mysterious-rise-of-ai-driven-credit-scoring-unlocking-the-future-of-finance-2a8ee628158d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img09.jpg"}
                      alt="credit genius blog-9"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      The Mysterious Rise of AI-Driven Credit Scoring: Unlocking
                      the Future of Finance
                    </h4>
                    <p>
                      In the shadows of the digital age, a powerful force is
                      quietly revolutionizing industries worldwide, and the
                      credit sector is no exception. Artificial intelligence
                      (AI) is reshaping how we assess creditworthiness
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://www.fastcompany.com/90882069/five-secrets-small-business-owners-should-know-about-credit"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img10.jpg"}
                      alt="credit genius blog-10"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      Five secrets small business owners should know about
                      credit
                    </h4>
                    <p>
                      Because these principles are not often taught in school,
                      try to do as much research as you possibly can so you know
                      what to expect when you walk into the bank.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://www.entrepreneur.com/money-finance/3-credit-secrets-that-will-help-you-qualify-for-the-house/418577"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/drem-home.jpg"}
                      alt="credit genius blog-11"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      3 Credit Secrets That Will Help You Qualify for the House
                      of Your Dreams
                    </h4>
                    <p>
                      For thousands of Americans nationwide, a common New Year's
                      resolution included buying a home. For some, this goal may
                      easily be within reach, for others it might seem more of a
                      dream.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://www.entrepreneur.com/money-finance/3-credit-secrets-millionaires-use-as-leverage/403651"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/leverage.jpg"}
                      alt="credit genius blog-12"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>3 Credit Secrets Millionaires Use as Leverage</h4>
                    <p>
                      As the old saying goes, the rich get richer while the poor
                      get poorer, but why is it that some people seem to have
                      the knack for accumulating wealth, seemingly without much
                      effort?
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://www.entrepreneur.com/money-finance/5-ways-to-get-a-higher-credit-card-limit-this-holiday-season/397101"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/credit-card.jpg"}
                      alt="credit genius blog-13"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      5 Ways to Get a Higher Credit-Card Limit This Holiday
                      Season
                    </h4>
                    <p>
                      This will come as no surprise, but the 2021 holiday season
                      is quickly approaching, and consumers are ramping up to
                      celebrate Christmas and the New Year.{" "}
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/leveraging-personal-credit-to-build-business-credit-a-step-by-step-guide-578cb9c5052d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img14.jpg"}
                      alt="credit genius blog-14"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      Leveraging Personal Credit to Build Business Credit: A
                      Step-by-Step Guide
                    </h4>
                    <p>
                      When you’re starting or growing a business, access to
                      credit can be a game-changer. However, many entrepreneurs
                      struggle to secure business credit, especially in the
                      early stages.{" "}
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/the-power-of-authorized-users-boosting-credit-scores-and-building-financial-stability-cb5326f7ff57"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img15.jpg"}
                      alt="credit genius blog-15"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      The Power of Authorized Users: Boosting Credit Scores and
                      Building Financial Stability
                    </h4>
                    <p>
                      In the world of credit management, the term “authorized
                      user” is often mentioned as a strategic tool for improving
                      credit scores and building a strong credit profile.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/understanding-mortgage-rates-and-credit-why-your-credit-score-matters-917b7856a396"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-img16.jpg"}
                      alt="credit genius blog-16"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      Understanding Mortgage Rates and Credit: Why Your Credit
                      Score Matters
                    </h4>
                    <p>
                      When it comes to buying a home, securing a favorable
                      mortgage rate is one of the most important factors that
                      can influence your financial future. But did you know that
                      your credit score plays a critical role in determining
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/the-5-best-credit-monitoring-apps-to-keep-your-financial-health-in-check-f382b17a9e82"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-15.jpg"}
                      alt="credit genius blog-17"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      The 5 Best Credit Monitoring Apps to Keep Your Financial
                      Health in Check
                    </h4>
                    <p>
                      In today’s fast-paced world, keeping track of your credit
                      score and financial health is more important than ever.
                      Credit monitoring apps have become essential tools,
                      allowing you to stay on top of your credit score, track
                      changes, and protect yourself against identity theft.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/credit-genius-revolutionizing-the-world-of-credit-management-505d96b385a3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-16.jpg"}
                      alt="credit genius blog-18"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      Credit Genius: Revolutionizing the World of Credit
                      Management
                    </h4>
                    <p>
                      In the fast-paced digital age, managing your credit
                      effectively is more important than ever. Credit Genius is
                      here to revolutionize how people approach credit
                      monitoring, education, and improvement.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/5-essential-mortgage-tips-to-secure-the-best-home-loan-c59a96e31563"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-17.jpg"}
                      alt="credit genius blog-19"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      5 Essential Mortgage Tips to Secure the Best Home Loan
                    </h4>
                    <p>
                      When it comes to buying a home, securing the right
                      mortgage is crucial to your financial well-being. Here are
                      five essential tips to help you navigate the mortgage
                      process and get the best deal possible.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/5-cool-things-about-american-express-that-most-people-dont-know-328fe04e7460"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-18.jpg"}
                      alt="credit genius blog-20"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      5 Cool Things About American Express That Most People
                      Don’t Know
                    </h4>
                    <p>
                      The American Express Centurion Card, often referred to as
                      the “Black Card,” is one of the most exclusive credit
                      cards in the world. It’s invitation-only and comes with a
                      hefty initiation fee and annual fee, but it offers
                      unparalleled perks such as personal concierge service,
                      access to private jet services, and elite status with
                      various luxury hotels and airlines.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/5-important-credit-matters-of-2024-and-how-to-master-them-cb1db7de0a76"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-19.jpg"}
                      alt="credit genius blog-21"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      5 Important Credit Matters of 2024 and How to Master Them
                    </h4>
                    <p>
                      As we progress through 2024, managing and improving credit
                      remains a key focus for many individuals. The financial
                      landscape is constantly changing, and staying informed
                      about essential credit matters can help you make sound
                      financial decisions.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/the-power-of-having-a-mentor-to-make-smart-credit-decisions-672bdad84b01"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-20.jpg"}
                      alt="credit genius blog-22"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      The Power of Having a Mentor to Make Smart Credit
                      Decisions
                    </h4>
                    <p>
                      Navigating the complexities of credit can be challenging,
                      especially for those who are new to personal finance or
                      trying to rebuild their credit. With so much at stake —
                      ranging from securing loans to achieving financial
                      stability — the decisions you make regarding your credit
                      can have long-lasting impacts on your financial future.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/5-myths-about-credit-that-arent-true-c8532d9ee83d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-21.jpg"}
                      alt="credit genius blog-23"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>5 Myths About Credit That Aren’t True</h4>
                    <p>
                      Credit can be a complex and sometimes confusing topic,
                      leading to various myths and misconceptions. Believing in
                      these myths can hinder your ability to manage credit
                      effectively. Here are five common credit myths that simply
                      aren’t true:
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/mortgage-rate-predictions-after-the-2024-election-what-homebuyers-need-to-know-59d45fa153c2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-22.jpg"}
                      alt="credit genius blog-24"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      Mortgage Rate Predictions After the 2024 Election: What
                      Homebuyers Need to Know
                    </h4>
                    <p>
                      As the dust settles after the 2024 presidential election,
                      all eyes are on the economic policies of the newly elected
                      administration and how they might influence mortgage
                      rates. Whether it’s Kamala Harris or Donald Trump who
                      takes office, their approach to handling the economy will
                      have significant implications for homebuyers and
                      homeowners alike.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/the-power-of-balance-transfers-a-smart-move-for-managing-credit-card-debt-c365706e638d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-23.jpg"}
                      alt="credit genius blog-25"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      The Power of Balance Transfers: A Smart Move for Managing
                      Credit Card Debt
                    </h4>
                    <p>
                      If you’re struggling with high-interest credit card debt,
                      a balance transfer can be a powerful tool to help you
                      regain control of your finances. By transferring your
                      existing credit card balances to a new card with a lower
                      interest rate — often 0% for an introductory period — you
                      can reduce the amount of interest you pay, allowing more
                      of your payments to go toward reducing the principal
                      balance.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/what-to-look-for-in-a-good-credit-monitoring-app-why-credit-genius-stands-out-91faf0d16ea6"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-24.png"}
                      alt="credit genius blog-26"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      What to Look for in a Good Credit Monitoring App: Why
                      Credit Genius Stands Out
                    </h4>
                    <p>
                      In today’s digital landscape, keeping track of your credit
                      has become a crucial aspect of managing your financial
                      health. A reliable credit monitoring app can help you stay
                      informed and proactive, providing the tools you need to
                      maintain and improve your credit score.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/refinancing-tips-how-to-get-the-most-out-of-your-home-loan-78cffbc626b9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-25.jpg"}
                      alt="credit genius blog-27"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      Refinancing Tips: How to Get the Most Out of Your Home
                      Loan
                    </h4>
                    <p>
                      Refinancing your mortgage can be a smart financial move,
                      especially when interest rates drop or your financial
                      situation improves. By refinancing, you can reduce your
                      monthly payments, shorten the term of your loan, or even
                      tap into your home’s equity for cash. However, refinancing
                      isn’t always the right choice for everyone, and it’s
                      important to approach the process with careful planning.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/building-a-strong-relationship-with-your-bank-why-it-matters-and-how-to-do-it-6c9158c42ff1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-26.jpg"}
                      alt="credit genius blog-28"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      Building a Strong Relationship with Your Bank: Why It
                      Matters and How to Do It
                    </h4>
                    <p>
                      In today’s fast-paced financial world, the relationship
                      you have with your bank can significantly impact your
                      financial health. Whether you’re a small business owner, a
                      new homeowner, or simply managing your personal finances,
                      cultivating a strong relationship with your bank can open
                      doors to better service, customized financial advice, and
                      even more favorable terms on loans and credit products.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/7-effective-ways-to-boost-your-credit-score-fast-5db67f7ae474"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-27.jpg"}
                      alt="credit genius blog-29"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>7 Effective Ways to Boost Your Credit Score Fast</h4>
                    <p>
                      A strong credit score is essential for securing favorable
                      loan terms, credit card approvals, and even rental
                      agreements. If your credit score needs improvement, the
                      good news is that there are several strategies you can
                      implement to see a boost relatively quickly.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/credit-mistakes-to-avoid-protecting-your-financial-health-20d0de7383f0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-28.jpg"}
                      alt="credit genius blog-30"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>
                      Credit Mistakes to Avoid: Protecting Your Financial Health
                    </h4>
                    <p>
                      Managing credit responsibly is key to maintaining a strong
                      financial foundation, but it’s easy to make mistakes that
                      can damage your credit score and limit your financial
                      options. Here are some common credit mistakes to avoid and
                      how to ensure you keep your credit in good standing.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/how-to-use-credit-wisely-during-the-holidays-ee9193ccc698"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-29.jpg"}
                      alt="credit genius blog-31"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>How to Use Credit Wisely During the Holidays</h4>
                    <p>
                      The holiday season is a time for celebration, gift-giving,
                      and spending time with loved ones. However, it’s also a
                      period where many people find themselves overspending,
                      often relying on credit cards to cover the costs. While
                      using credit can be a convenient way to manage holiday
                      expenses, it’s important to use it wisely to avoid
                      starting the new year with a financial hangover.
                    </p>
                  </div>
                </div>
              </a>
            </div>





            <div className={styles.item}>
              <a
                href="https://x.com/CreditGeniusApp/status/1825901233829458407"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-32.jpg"}
                      alt="credit genius blog-32"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>Is Credit Genius the Best Credit Monitoring App in the United States? </h4>
                    <p>In a world where credit health is more important than ever, credit monitoring apps have become essential tools for consumers looking to protect and improve their financial standing. Among the many options available, Credit Genius has gained considerable attention for its innovative approach and user-friendly features. </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://x.com/CreditGeniusApp/status/1825762923790348450"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-33.jpg"}
                      alt="credit genius blog-33"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>How To Become A Rockstar With Credit</h4>
                    <p>When we think of rockstars, images of stage lights, roaring crowds, and the thrill of living life on your own terms often come to mind. But being a rockstar isn’t just about music—it’s about mastering your domain and excelling in every aspect of your life, including your finances.</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/credit-genius/drive-down-your-rates-smart-credit-tips-for-auto-refinancing-in-2024-5db52f464b53"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-34.jpg"}
                      alt="credit genius blog-34"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>Smart Credit Tips for Auto Refinancing in 2024</h4>
                    <p>In 2024, getting the best interest rate on a car loan requires a strategic approach, especially in a fluctuating economic environment. The good news is that there are proven methods to secure the lowest rate possible, and credit expert Antoine Sallis has some powerful tips to share.</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/credit-genius/3-essential-credit-tips-for-choosing-the-right-bank-d18753540a71"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-35.jpg"}
                      alt="credit genius blog-35"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>3 Essential Credit Tips for Choosing the Right Bank</h4>
                    <p>Selecting the right bank is a crucial step in managing your financial health and building a strong credit profile. With countless options available, it’s easy to feel overwhelmed. </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://x.com/CreditGeniusApp/status/1827067093222879600"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-36.jpg"}
                      alt="credit genius blog-36"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>Credit Genius Tips for Securing the Highest Credit Card Limits</h4>
                    <p>When it comes to credit cards with the highest credit limits, several banks and cards stand out for offering substantial limits, often exceeding $50,000 or even $100,000 for well-qualified applicants. </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/credit-genius/how-to-do-a-balance-transfer-a-step-by-step-guide-cf29a5fdfa2a"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-37.jpg"}
                      alt="credit genius blog-37"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>How to Do a Balance Transfer: A Step-by-Step Guide</h4>
                    <p>A balance transfer can be a powerful tool for managing and reducing your debt, especially if you’re struggling with high-interest credit card balances. By transferring your balance from a high-interest card to one with a lower interest rate, you can save money on interest charges and pay off your debt faster. </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/what-is-the-difference-between-a-soft-inquiry-and-a-hard-inquiry-6bee5fc78ed9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-38.jpg"}
                      alt="credit genius blog-38"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>What is the Difference Between a Soft Inquiry and a Hard Inquiry?</h4>
                    <p>When it comes to understanding credit, one of the most commonly asked questions is about the difference between a soft inquiry and a hard inquiry. Both play a role in your credit report, but they affect your credit score in different ways. </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/credit-genius/how-do-i-build-credit-if-i-have-none-or-very-little-2a0abf27e420"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-39.jpg"}
                      alt="credit genius blog-39"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>How Do I Build Credit If I Have None or Very Little?</h4>
                    <p>Starting with no credit or having very little credit can feel like a catch-22: you need credit to build credit, but without any credit history, it can be challenging to get approved for loans or credit cards.</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://x.com/CreditGeniusApp/status/1828247464044064879"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-40.jpg"}
                      alt="credit genius blog-40"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>3 Easy Tips to Boost Your Credit Score</h4>
                    <p>Improving your credit score doesn’t have to be complicated or time-consuming. With the right approach, you can make significant progress toward a better score by focusing on a few key areas.</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/how-to-protect-your-data-online-essential-tips-for-staying-safe-in-the-digital-world-fa0e52af8839"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-41.jpg"}
                      alt="credit genius blog-41"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>Essential Tips for Staying Safe in the Digital World</h4>
                    <p>In today’s digital age, our lives are increasingly intertwined with the internet. From banking to shopping, social media to email, we rely on online platforms for countless aspects of our daily routines.</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/maximizing-travel-points-with-credit-cards-your-ultimate-guide-f965ccc6fd9a"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-42.jpg"}
                      alt="credit genius blog-42"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>Maximizing Travel Points with Credit Cards: Your Ultimate Guide</h4>
                    <p>Travel points are one of the most exciting perks that come with many credit cards. They offer a fantastic way to earn rewards for everyday spending, allowing you to save on flights, hotels, and other travel-related expenses.</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://www.forbes.com/councils/forbesfinancecouncil/2024/08/22/9-tips-to-find-the-best-credit-cards/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-43.jpg"}
                      alt="credit genius blog-43"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>9 Tips To Find The Best Credit Cards</h4>
                    <p>Credit cards are more than simply tools for making purchases; they also come with perks such as balance transfer options, travel incentives and cash back. You might be intimidated when choosing a credit card because there are so many options available. </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://x.com/CreditGeniusApp/status/1828625271291027909"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-44.jpg"}
                      alt="credit genius blog-44"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>How to Get Out of Debt Fast: A Strategic Guide</h4>
                    <p>Debt can feel like a heavy burden, weighing you down and limiting your financial freedom. However, with the right strategies and a disciplined approach, you can significantly accelerate your journey to becoming debt-free. Here’s a step-by-step guide on how to get out of debt fast.</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/5-effective-ways-to-increase-your-credit-card-limit-3e9782c8fc74"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-45.jpg"}
                      alt="credit genius blog-45"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>5 Effective Ways to Increase Your Credit Card Limit</h4>
                    <p>Increasing your credit card limit can provide greater financial flexibility, improve your credit utilization ratio, and even boost your credit score. However, it’s important to approach this responsibly.</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://x.com/CreditGeniusApp/status/1830633185107194116"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-46.jpg"}
                      alt="credit genius blog-46"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>Leveraging Personal Credit to Boost Your Business Credit</h4>
                    <p>In the world of entrepreneurship, credit is king. Whether you're just starting out or looking to expand, having solid credit can open doors to financing opportunities that can propel your business forward.</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://x.com/CreditGeniusApp/status/1830764371183837413"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-47.jpg"}
                      alt="credit genius blog-47"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>How Personal Credit Can Boost Your Business Credit: A Strategic Advantage for Entrepreneurs</h4>
                    <p>In the dynamic world of entrepreneurship, access to credit is a crucial factor that can determine the success or failure of a business. While business credit is vital for securing loans, favorable terms with suppliers, and other financial opportunities</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/credit-for-beginners-a-simple-guide-to-understanding-and-building-your-credit-880a59a58030"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-48.jpg"}
                      alt="credit genius blog-48"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>A Simple Guide to Understanding and Building Your Credit</h4>
                    <p>Credit is a fundamental part of our financial lives, yet it can seem confusing and intimidating, especially if you’re just starting out. Whether you’re looking to buy your first car, rent an apartment, or simply build a solid financial foundation, understanding credit is essential. </p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/credit-101-what-every-teen-should-know-about-credit-df42b902a9ec"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-49.jpg"}
                      alt="credit genius blog-49"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>What Every Teen Should Know About Credit</h4>
                    <p>Hey there! So, you’re 17 now, and you’ve probably heard people talking about credit. It might sound like one of those adult things you don’t need to worry about yet, but understanding credit now can really set you up for the future.</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://medium.com/@creditgenius/credit-genius-the-ultimate-game-changer-in-credit-management-446a211d31b6"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-50.jpg"}
                      alt="credit genius blog-50"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>The Ultimate Game-Changer in Credit Management</h4>
                    <p>In today’s fast-paced world, managing credit can feel overwhelming. With so many factors affecting your credit score, it’s easy to feel lost in a sea of numbers and jargon.</p>
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.item}>
              <a
                href="https://x.com/CreditGeniusApp/status/1832144073156940194"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-51.jpg"}
                      alt="credit genius blog-51"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>Top 5 Credit Monitoring Sites You Should Know: Credit Genius and Beyond</h4>
                    <p>When it comes to keeping track of your credit score and staying financially healthy, finding the right credit monitoring service is essential. While Credit Genius takes the top spot with its AI-powered tools and gamification</p>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.item}>
              <a
                href="https://x.com/CreditGeniusApp/status/1832145669316034606"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.blog_box}>
                  <div className={styles.blog_img}>
                    <img
                      src={"./images/blog-52.jpg"}
                      alt="credit genius blog-52"
                    />
                  </div>
                  <div className={styles.blog_content}>
                    <h4>Elon Musk: Harnessing AI and Business Credit to Revolutionize the Future </h4>
                    <p>Elon Musk is one of the most influential entrepreneurs of our time, known for his vision of a future powered by cutting-edge technology. From electric vehicles and space travel to artificial intelligence (AI) and clean energy, </p>
                  </div>
                </div>
              </a>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <section className={styles.download_section}>
        <div id="downloadApp" className="container">
          <div className="row">
            <div className="col-md-7">
              <h2>download app</h2>
              <h3>
                For better experience, <span></span>download Our app now
              </h3>
              <p>
                Credit Genius is an educational platform revolutionizing people
                look at their credit and finance. Learn the step by step
                strategies, mysteries and secrets that has been hidden from us.
              </p>
              <div className={styles.app_link_action}>
                <a href="/">
                  <img src={"./images/app-store.png"} alt="app-store" />
                </a>
                <a href="/">
                  <img src={"./images/google-play.png"} alt="play-store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
